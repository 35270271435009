import React from "react";
import { SignedOut, SignInButton } from "@clerk/clerk-react";
import { Button } from "antd";

export default function SigninButtonClerk() {
  return (
    <SignedOut>
      <SignInButton>
        <Button>Login</Button>
      </SignInButton>
    </SignedOut>
  );
}
