import { Flex, Typography } from "antd";
import React from "react";
import "./ContentHomePage.css";

export default function ContentHomePage() {
  return (
    <Flex className="content-box" justify={"center"} align="center">
      <Typography.Title style={{color:"#E6E6E6"}}>Bem-vindo á loja Pearls&Bela!</Typography.Title>
    </Flex>
  );
}
