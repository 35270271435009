import { Button, ConfigProvider, Flex } from "antd";
import React from "react";
import "./navBar.css";
import Logo2 from "../../images/Logo 2.png";
import SignedInUserButtonClerk from "../signedInUserButtonClerk";
import SigninButtonClerk from "../signInButtonClerk";

export default function NavBar() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultActiveColor: "#C5A880",
            colorBorder:"transparent"
          },
        },
        token: {
          colorPrimary:"#C5A880",
          colorBgContainer:"transparent",
          colorText:"#C5A880"
          
        },
      }}
    >
      <Flex style={{ width: "100vw", padding: "1rem" }} className="Navbar">
        <img src={Logo2} alt="logo" style={{ width: "25%" }} />
        <Flex
          justify={"space-evenly"}
          align="center"
          style={{ color: "#C5A880", width: "100%" }}
        >
          <Button>Loja</Button>
          <Button>Personaliza as tuas pérolas</Button>
          <Button>Contacto</Button>
          <SignedInUserButtonClerk />
          <SigninButtonClerk />
        </Flex>
      </Flex>
    </ConfigProvider>
  );
}
