import { Flex } from "antd";
import React from "react";
import ContentHomePage from "../../components/ContentHomePage/ContentHomePage";
import NavBar from "../../components/NavBar/navBar";
import "./homePage.css";

export default function HomePage() {
  return (
    <Flex vertical>
      <NavBar />
      <ContentHomePage/>
    </Flex>
  );
}
