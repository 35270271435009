import React from "react";
import { SignedIn, UserButton, useUser } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import Typography from "antd/es/typography/Typography";
import { Flex } from "antd";

export default function SignedInUserButtonClerk() {
  const { user, isSignedIn } = useUser();
  return (
    <SignedIn>
      <Flex align={"center"} gap={5}>
        <UserButton
          afterSignOutUrl="/"
          appearance={{
            baseTheme: dark,
          }}
        />
        {isSignedIn && <Typography>Bem-vindo {user.fullName}</Typography>}
      </Flex>
    </SignedIn>
  );
}
